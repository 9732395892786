import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Job, Team } from "utils"
import { Select } from "components/library"

type P = {
	teams: Team[]
	selectedTeams: Team[]
	totalJobs: number
	jobs: Job[]
	setSelectedTeams: Dispatch<SetStateAction<Team[]>>
	locations: string[]
	preferredLocation: string
	setPreferredLocation: Dispatch<SetStateAction<string>>
}

export const Sidebar = ({
	teams,
	selectedTeams,
	setSelectedTeams,
	totalJobs,
	jobs,
	locations,
	preferredLocation,
	setPreferredLocation,
}: P) => {
	const { t } = useTranslation()

	return (
		<aside className="flex flex-col gap-8 col-span-1">
			{/* <Input placeholder="Search Position" /> */}

			<Select
				className="w-full"
				value={preferredLocation}
				onChange={(e) => setPreferredLocation(e.target.value)}
			>
				{locations.map((loc) => {
					return <option value={loc}>{loc}</option>
				})}
			</Select>

			<div className="uppercase">{t("common.words.teams")}</div>
			<ul className="flex flex-col gap-3">
				<li
					className={`${
						selectedTeams.length === 0 ? "text-theme-blue" : "text-[#C1C7D0]"
					} cursor-pointer`}
					onClick={() => setSelectedTeams([])}
				>
					All Teams ({totalJobs})
				</li>

				{teams.map((team) => {
					return (
						<li
							onClick={() => {
								if (!selectedTeams.includes(team)) {
									setSelectedTeams([...selectedTeams, team])
								} else {
									setSelectedTeams(selectedTeams.filter((t) => t !== team))
								}
							}}
							key={team}
							className={`${
								selectedTeams.includes(team)
									? "text-theme-blue"
									: "text-[#C1C7D0]"
							} cursor-pointer`}
						>
							{team} ({jobs.filter((job) => job.team === team).length})
						</li>
					)
				})}
			</ul>
		</aside>
	)
}
