import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Job } from "utils"
import { JobCard } from "./job-card"

type P = {
	jobs: Job[]
}

export const OpenPositions = ({ jobs }: P) => {
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-12 col-span-4">
			<div className="uppercase">{t("careers.open-positions.heading")}</div>

			<div className="flex flex-col gap-8">
				{jobs.map((job, i) => {
					return (
						<JobCard
							key={job.position}
							job={job}
							className={
								i !== jobs.length - 1 ? "border-b border-[#DFE1E6]" : ""
							}
						/>
					)
				})}
			</div>
		</section>
	)
}
