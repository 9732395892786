import React, { ComponentPropsWithoutRef } from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { Job, mergeClasses } from "utils"
import { PrimaryBtn } from "components/library"

type P = ComponentPropsWithoutRef<"div"> & {
	job: Job
}

export const JobCard = ({ job, className = "", ...props }: P) => {
	const { t } = useTranslation()

	return (
		<div
			className={mergeClasses({
				classes: ["flex flex-col gap-4 pb-6", className],
			})}
			{...props}
		>
			<div className="flex flex-col gap-4 sm:gap-2">
				<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
					<div className="flex gap-4 items-center">
						<h3 className="text-xl sm:text-2xl text-theme-blue">
							{job.position}
						</h3>
						<span
							className={`px-2 text-sm rounded-sm text-white ${
								job.job_type === "Fulltime"
									? "bg-green-500"
									: job.job_type === "Internship"
									? "bg-blue-400"
									: "bg-orange-400"
							}`}
						>
							{job.job_type}
						</span>
					</div>

					<Link to={`/careers/${job.slug}`} className="shrink-0">
						<PrimaryBtn tabIndex={-1} className="w-full">
							Apply
						</PrimaryBtn>
					</Link>
				</div>

				<p className="uppercase text-[#C1C7D0]">
					{t("common.messages.years-of-exp", { years: job.experience })}
					{", "}
					{job.location.join(", ")}
				</p>
			</div>

			<p className="sm:max-w-[80%]">{job.excerpt}</p>
		</div>
	)
}
