import React, { useMemo, useState } from "react"
import { Job, Team } from "utils"
import { OpenPositions } from "./open-positions"
import { Sidebar } from "./sidebar"

type P = {
	jobs: Job[]
}

export const Listing = ({ jobs }: P) => {
	const teams: Team[] = useMemo(
		() => [...new Set(jobs.map((job) => job.team))],
		[jobs]
	)
	const locations = useMemo(() => {
		return [...new Set(jobs.map((job) => job.location).flat())]
	}, [jobs])
	const [selectedTeams, setSelectedTeams] = useState<Team[]>([])
	const [preferredLocation, setPreferredLocation] = useState<string>("")

	const filteredJobs = useMemo(() => {
		let filteredJobs = jobs
		if (selectedTeams.length > 0) {
			filteredJobs = jobs.filter((job) => selectedTeams.includes(job.team))
		}
		if (!!preferredLocation) {
			filteredJobs = jobs.filter((job) =>
				job.location.includes(preferredLocation)
			)
		}
		return filteredJobs
	}, [jobs, selectedTeams])

	return (
		<div className="flex flex-col sm:grid grid-cols-5 gap-8">
			<Sidebar
				selectedTeams={selectedTeams}
				setSelectedTeams={setSelectedTeams}
				teams={teams}
				jobs={jobs}
				totalJobs={jobs.length}
				locations={locations}
				preferredLocation={preferredLocation}
				setPreferredLocation={setPreferredLocation}
			/>
			<OpenPositions jobs={filteredJobs} />
		</div>
	)
}
